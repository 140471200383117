.notification {
  padding 10px
  margin-bottom 20px
  &__outer {
    display flex
    margin 0 -5px
    align-items center
  }
  &__itm {
    padding 0 5px
  }
  &__text {
    font-size: 18px;
  }
  &--error {
    background: redLight;
    .notification__text {
      color: redColor;
    }
  }
}
