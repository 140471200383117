.icon {
  display block
  &.icon--password {
    width 25px
    height 15px
  }
  &.icon--error {
    width: 25px;
    height: 25px;
    fill redColor;
  }
  &.icon--success {
    width: 25px;
    height: 25px;
    fill greenColor;
  }
  &.icon--plus {
    width 28px
    height 28px
  }
  &.icon--phone {
    width 28px
    height 28px
  }
  &.icon--task {
    width 28px
    height 28px
  }
  &.icon--search {
    width 28px
    height 28px
    fill #979797
  }
  &.icon--date {
    width: 22px;
    height: 25px;
    fill #979797
  }
  &.icon--call {
    width: 30px;
    height: 30px;
  }
  &.icon--flag {
    width 20px
    height 20px
  }
  &.icon--lock {
    width: 24px;
  }
  &.icon--check {
    margin-right 10px
    margin-left 0
  }
  &.icon--warning {
    margin-left 8px
  }
  &.icon--close {
    cursor pointer
  }
  &.icon--trash {
    margin 0 auto
  }
  &.icon--arrow-down {
    margin-left 11px
  }
  &.icon--sort {
    margin: 0 5px
  }
  &.icon--bell, &.icon--bell-check {
    width 20px
    height 20px
    margin-right 5px
  }
}
