.nav {
  height 100%
  &__list {
    display flex
    height 100%
  }
  &__li {
    padding 0 2px
  }
  &__link {
    min-width 120px
    padding 0 15px
    display flex
    border-radius: 10px;
    transition background-color 0.2s, color 0.2s
    color: #333333;
    font-size: 20px;
    font-weight: 400;
    text-decoration none
    height 100%
    &:hover {
      background: #1976d2;
      color #fff
    }
  }
  &__text {
    margin auto
  }
  &__link--active {
    background: #1976d2;
    color #fff
  }
}
