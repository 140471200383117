.title-h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom 20px
}
.title-h1 {
  font-size: 64px;
  font-weight: 700;
  margin-bottom 25px
}
.title-h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom 20px
}
