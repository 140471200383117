.list {
  &__li {
    display block
    margin-bottom 10px
    position relative
    padding-left 20px
    &:after {
      position absolute
      top 4px
      left 0
      border-radius 50%
      content ''
      display block
      width: 10px;
      height: 10px;
      background #999999
    }
  }
  &__li--success {
    &:after {
      background: greenColor;
    }
  }
}
