.orders-summary-header {
	display flex
	align-items center
	justify-content space-between
	margin-bottom 20px
	.link {
		color #0f73e7
		text-decoration underline
	}
	.btn-link {
		padding 30px 35px
		border-radius 20px
		font-size 18px
		font-weight: 500
		color #0F73E7
		background-color #fff
		box-shadow 0 2px 2px 0 rgba(56,142,200,0.30)
	}
}

.order-summer-content {
	border-radius 20px
	background-color #fff
}

.order-summer-info {
	padding 23px 30px
	border-radius 20px
	background-color #F8FAFC
	margin-bottom 30px
	&__header {
		display flex
		align-items center
		justify-content space-between
		margin-bottom 20px
		.btn-block {
			display flex
			align-items center
			justify-content flex-end
		}
		.btn-dd {
			margin-left 20px
		}
	}
	&__table {
		width 100%
		display inline-flex
		margin-bottom 50px
	}
	&__notes {
		width 680px
		margin-right 20px
		&--title {
			font-size 24px
			font-weight 500
			color #333
			margin-bottom 20px
		}
		&--content {

		}
	}
	&__attachments {
		&--title {
			font-size 24px
			font-weight 500
			color #333
			margin-bottom 20px
		}
		&--product-list-img {
			display flex
			justify-content flex-start
		}
		&--product-img {
			width 75px
			margin-right 20px
			img {
				width 100%
			}
		}
	}
}

.order-summer-tabs-order-total {
	display flex
	justify-content flex-start
	padding 0 20px 20px
}

.order-summer-tabs {
	width 72%
	margin-right 20px
}

.order-summer-order-total {
	width 25%
	margin-top 65px
	&__title {
		font-size 24px
		font-weight 500
		color #333
		margin-bottom 20px
	}
	&__content {
		width 100%
		table {
			width 100%
		}
		td {
			padding 0 20px 10px 0
			&:last-child {
				font-size 16px
				font-weight 500
				text-align right
				padding 0 0 10px 0
			}
		}
	}
}

.tabs-claims {
	width 100%
	position relative
	.btn-dd {
		position absolute
		top: 0px
		right 0px
	}
	&__date {
		font-size 16px
		line-height 27px
		font-weight 500
		color #333333
		margin-bottom 10px
	}
	.tabs-claims__table {
		width 100%
		td {
			padding 0 20px 10px 0
		}
	}
}

.items-order {
	border-top: 1px solid #ccc;
	&:first-child {
		border none
	}
	&__title {
		font-size 16px
		line-height 27px
		font-weight 500
		color #333333
		margin-bottom 25px
	}
	&__content {
		display flex
		justify-content flex-start
		margin-bottom 25px
	}
	&__img {
		width 75px
		margin-right 25px
		img {
			width 100%
		}
	}
	&__info {
		padding-right 20px
		flex 1
		width 0
		&--title {
			font-size 16px
			line-height 27px
			font-weight 500
			color #333333
		}
		&--list {
			display inline-flex
		}
		&--list-content {
			font-size 16px
			line-height 27px
			font-weight 300
			color #333333
			margin-right 25px
		}
	}
	&__price {
		width 120px
		padding-right 20px
		padding-top 4px
	}
	&__btn {
		width 270px
		display flex
		flex-flow column
		.btn-dd {
			justify-content center
		}
		&--refund {
			display flex
			align-items center
			justify-content space-between
			font-size 16px
			line-height 27px
			font-weight 500
			color #136C00
			margin-bottom 12px
		}
	}
}

.container-tabs-wrapper {
	width 100%
}

.tabs-order {
	margin-bottom 20px
}

.tabs-order-wrapper {
	display flex
	align-items center
	justify-content space-between
	margin-top 25px
}

.tabs-history {
	.form__full {
		border-bottom 1px solid #ccc
	}
	.history-calendar-data, .history-calendar-card {
		margin-left 70px
	}
}

.tabs-history-tags {
	display flex
	flex-wrap wrap
	padding 20px 20px 0px
}

.history-calendar {
	width 840px
}

.history-calendar-data {
	position relative
	width 100%
	padding 40px 0 50px 44px
	font-size 24px
	font-weight 500
	color #333333
	display block
	&:before {
		content ''
		position absolute
		top 0px
		left 12px
		width 2px
		height 100%
		background-color #D8D8D8
	}
}

.history-calendar-card {
	position relative
	width 100%
	padding 0 0 50px 44px
	&:before {
		content ''
		position absolute
		top 0px
		left 12px
		width 2px
		height 100%
		background-color #D8D8D8
	}
	&__circle {
		width 24px
		height 24px
		position absolute
		top 0px
		left 1px
		background #FFFFFF
		box-shadow 0 2px 1px -1px rgba(0,0,0,0.20), 0 1px 1px 0 rgba(0,0,0,0.12), 0 2px 1px -1px rgba(0,0,0,0.14)
		border-radius 12px
		&:before {
			content ''
			position absolute
			width 16px
			height 16px
			background-color #9E9E9E
			top 50%
			left 50%
			transform: translate(-50%, -50%);
			border-radius 50%
		}
	}
	&-content {
		display flex
		justify-content space-between
	}
	&__content {
		text-align left
		width 550px
		&--title {
			font-size 16px
			line-height 27px
			font-weight 500
			color #333333
		}
		&--content {

		}
	}
	&__data {
		text-align right
		width 200px
	}
	.tags-history {
		display inline-flex
	}
}

.making-new-order__header {
	margin 0 -15px
	position relative
	display flex
	justify-content space-between
	.link-back {
		font-size 16px
		font-weight 300
		color #0F73E7
		text-decoration underline
		position absolute
		top 0px
		left 0px
	}
	.title-h2 {
		padding-top 55px
	}
}

.making-new-order__content {
	display flex
	align-items flex-start
	justify-content space-between
	margin 20px -15px
}

.making-new-order {
	flex 1
	width 0
	position relative
	margin 0 auto
	padding-right 20px
	.tabs__caption {
		padding-top 15px
	}
}

.card-box {
	width 340px
	.panel__outer {
		width 100%
		padding 25px 30px
	}
	.title-h2 {
		margin-bottom 15px
		&.margin-top {
			margin-top 27px
		}
	}
}

.making-order-list {
	width 100%
}

.making-order-info {
	display flex
	align-items stretch
	justify-content space-between
	padding 20px 30px
	background-color white
	border-bottom 1px solid #ccc
	&.gray {
		background-color #d0d8dc
	}
	&.red {
		background #ffebee
	}
	&:firs-child {
		border-bottom 0
	}
	&__img {
		width 80px
		margin-right 30px
	}
	&__col {
		display flex
		align-items center
		&.flex-col {
			flex-flow column
			align-items flex-end
		}
	}
	&__content {
		&--title {
			font-size 16px
			line-height 27px
			font-weight 500
			color #333333
			margin-bottom 7px
		}
		&--items {
			display flex
		}
		&--desc {
			font-size 12px
			font-weight 300
			color #5D656D
			span {
				font-weight 500
				color #0073FF
			}
		}
	}
	&__box {
		margin-bottom 11px
		margin-right: 15%;
		span {
			font-size 12px
			font-weight 300
			color #5E656C
			display block
			&:last-child {
				font-size 16px
				font-weight 300
				color #3A3A3A
			}
		}
		&:last-child {
			margin-right 0
		}
	}
	&__price {
		font-size 18px
		font-weight 500
		line-height 22px
		color #333333
	}
	&__items {
		font-size 12px
		font-weight 300
		color #5D656D
		margin-bottom 8px
	}
	&__btn {
		display flex
		align-items center
		justify-content flex-end
	}
}

.shopping-cart {
	position: relative
	padding 15px 0
	border-bottom 1px solid #CCCCCC
	.b-close {
		width 18px
		height 18px
		position absolute
		top 7px
		right -10px
	}
	&__title {
		font-size 16px
		font-weight 300
		color #3A3A3A
		margin-bottom 15px
	}
	&__content {
		display flex
		align-items center
		justify-content space-between
		margin-bottom 14px
		&--name {
			font-size 16px
			font-weight 300
			color #3A3A3A
			text-align left
		}
		&--price {
			font-size 16px
			font-weight 300
			color #3A3A3A
			text-align right
		}
	}
	&__footer {
		display flex
		align-items flex-end
		justify-content space-between
		&--save {
			font-size 16px
			font-weight 300
			color #0F73E7
			text-decoration underline
			text-align left
		}
		&--price {
			font-size 24px
			font-weight 500
			color #333333
			text-align right
		}
	}
}

.shipping-address {
	&__title {
		font-size 16px
		font-weight 300
		line-height 20px
		color #333333
	}
	&__link {
		display flex
		align-items center
		justify-content space-between
		margin-top 16px
		a {
			font-size 16px
			font-weight 300
			color #0F73E7
			text-decoration underline
			&:first-child {
				text-align left
			}
			&:last-child {
				text-align right
			}
		}
	}
}

.delivery-options {
	display flex
	align-items center
	justify-content space-between
	margin-top 40px
	&__title {
		font-size 16px
		font-weight 300
		line-height 20px
		color #333333
	}
}

.summary {

}

.summary-header {
	display flex
	align-items flex-end
	justify-content space-between
	&__title {
		font-size 16px
		font-weight 300
		line-height 20px
		color #3A3A3A
		text-align left
	}
	&__price {
		font-size 24px
		font-weight 500
		line-height 32px
		color #333333
		text-align right
	}
}

.summary-footer {
	width 100%
	font-size 24px
	font-weight 500
	line-height 32px
	color #333333
	text-align right
	margin-bottom 16px
}

.summary-btn {
	text-transform uppercase
	font-size 18px
	font-weight 500
	color #fff
	padding 15px 0
	background-color #2979ff
	border-radius 4px
	cursor pointer
	text-align center
}

.orders-tabs {
	display none
	&.active-table {
		display block
	}
}