*, *:before, *:after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
html,
body {
  font-family: 'Roboto', sans-serif;
  font-weight 400
  font-size 16px
  height: 100%;
  color: #333333;
  background: blueLight;
}
img {
  max-width: 100%;
}
.link {
  color #0f73e7
  transition opacity 0.2s
  &:hover {
    opacity 0.7
  }
}
.container {
  max-width 1880px
  margin: 0 auto;
  padding: 0 15px;
}
.container-tabs {
  width 100%
  border-radius 20px
  background-color #fff
  margin-bottom 30px
}
.main {
  &.main--login {
    height 100%
    display flex
    justify-content center
    align-items center
    .content {
      margin auto
      padding 15px 0
    }
  }
}
.content {
  padding 120px 15px 0
}
.scroll-hidden {
  overflow: hidden
}
.block-flex-start {
  display flex
  justify-content flex-start
}
.tabs {
}
.tabs__caption {
  display flex
  align-items center
  justify-content flex-start
  border-bottom 1px solid #CCCCCC
  padding 0 20px
  li {
    font-size 14px
    font-weight 400
    color #5E656C
    padding 16px
    border-bottom  4px solid transparent
    cursor pointer
    text-transform uppercase
    &.active {
      color #0F73E7
      border-bottom  4px solid #0F73E7
    }
  }
}
.tabs__content {
  display none
  padding 30px 20px 15px
  &.active {
    display block
  }
}
.tabs-search-box {
  width 100%
  display flex
  align-items center
  justify-content space-between
  margin-bottom 20px
  &__input {
    width 80%
    display flex
    align-items stretch
    justify-content space-between
    .form__label {
      border-right 0
      border-radius 0
    }
  }
  &__btn {
    font-size 20px
    line-height 24px
    font-weight: 300
    text-decoration underline
    color #0F73E7
    cursor pointer
  }
}

.tabs-search-filter {
  display flex
  align-items center
  .form__label {
    margin-bottom 20px
  }
}

.counter-box {
  display flex
  align-items stretch
  margin-left 20px
  &__minus, &__pluse {
    display flex
    align-items center
    justify-content center
    cursor pointer
    background-color #E3F2FF
    width 40px
    height 40px
    color #0F73E7
    font-size 18px
    font-weight: 500
  }
  &__text {
    display flex
    align-items center
    justify-content center
    width 40px
    height 40px
    color #575757
    font-size 12px
    font-weight: 300
    border-top 1px solid #ccc
    border-bottom 1px solid #ccc
  }
}