.panel {
  &.panel--tasks {
    .panel__outer {
      padding-bottom 0
    }
  }
  &--user-info {
    .panel__itm {
      border-bottom none
    }
    .panel__outer {
      margin-bottom 30px
    }
    .find-user {
      padding 15px 30px 20px
    }
  }
  &--user {
    position relative
    .panel__itm {
      padding-top 45px
    }
  }
  &--right {
    display: flex
    justify-content flex-end
    .panel__outer {
      width 50%
    }
  }
  &__outer {
    padding 15px 0
    background #ffffff
    border-radius 20px
    &.panel--active {
      background greenLight
    }
    &.panel--outdated {
      background #fff
    }
    &.panel--active-from {
      background greenLight
    }
    &.panel--locked {
      background yellowLight
    }
    &.panel--good-customer {
      background greenLight
      .user-profile-status__tags--not-active {
        color #89C541
        border 2px solid #89C541
      }
      .user-profile-status__tags--active {
        color white
        background greenColor
      }
    }
    &.panel--customer-blocked {
      background #D0D8DC
      .user-profile-status__tags--not-active {
        color #485A63
        border 2px solid #485A63
      }
      .user-profile-status__tags--active {
        color white
        background #485A63
      }
    }
    &.panel--angry-customer {
      background #FFEBEE
      .user-profile-status__tags--not-active {
        color #C62828
        border 2px solid #C62828
      }
      .user-profile-status__tags--active {
        color white
        background #C62828
      }
    }
    &.panel--good-amber-customer {
      background #FFF7E6
      .user-profile-status__tags--not-active {
        color #FFA000
        border 2px solid #FFA000
      }
      .user-profile-status__tags--active {
        color white
        background #FFA000
      }
    }
    &.panel--lying-customer {
      background #D7CCC8
      .user-profile-status__tags--not-active {
        color #6D4C41
        border 2px solid #6D4C41
      }
      .user-profile-status__tags--active {
        color white
        background #6D4C41
      }
    }
  }
  &__row {
    display flex
    margin 0 -15px
  }
  &__col {
    padding 0 15px
  }
  &__col--left {
    width 462px
  }
  &__col--right {
    flex-grow 1
    width 0
  }
  &__itm {
    padding 15px 30px
    border-bottom 1px solid #d8d8d8
  }
  &__item {
    padding 20px
  }
  &__itm--task {
    padding 0
  }
  .title-h2 {
    margin-bottom 0
  }
  .title-h3 {
    margin-bottom 0
  }
  &--members, &--calls, &--orders {
    margin-top 45px
    margin-bottom: 60px;
    .title-h2 {
      margin-left 30px
      margin-bottom 25px
    }
    .panel__outer {
      margin-bottom 20px
    }
    .panel__itm {
      border none
    }
    .search__inp {
      font-size 16px
      line-height 19px
    }
  }
  &--making-new-order {

  }
}