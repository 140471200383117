.popup {
  padding 30px
  background #fff
  border-radius 10px
  display none
  &.popup--new-task {
    width 980px
  }
  &.popup--log-issue {
    width 1120px
    overflow-y auto
    max-height 95%
    margin-top 1.5%
  }
  &.popup--new-notes {
    width 1140px
    .scroll-table {
      max-height 500px
      overflow scroll
    }
  }
  &.success{
    background greenLight
  }
  &__info {
    font-weight: 700;
    color #333
  }
  &__row {
    display flex
    align-items center
    justify-content space-between
    margin 0 -10px
  }
  &__col {
    padding 0 10px
  }
  &__top {
    margin-bottom: 25px;
    display flex
    align-items center
    justify-content space-between
  }
  &__close {
    display block
    width: 23px;
    position relative
    height: 23px;
    &:after,
    &:before {
      position: absolute;
      content: '';
      display: block;
      background: #333333;
      width: 3px;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: background .2s;
    }
    &:after {
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
    }
  }
  .title-h2 {
    margin-bottom 0
  }
  &__header-btn {
    display flex
    align-items center
    justify-content space-between
    padding-bottom 30px
    border-bottom 1px solid #979797
    margin-bottom 20px
    .toggle-check {
      margin-left 12px
    }
  }
}