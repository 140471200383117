.dropdown {
  min-width 280px
  position relative
  padding 10px 0
  height 100%
  &.active {
    .dropdown__bottom {
      opacity 1
      visibility visible
    }
    .dropdown__btn {
      border-color #1976d2
    }
  }
  &:hover {
    .dropdown__btn {
      border-color #1976d2
    }
  }
  &__top {
    display block
    border-radius 10px
    background #ffffff
    border 1px solid #1976d2
    padding 15px
    height 100%
  }
  &__outer {
    display flex
    align-items center
    margin 0 -5px
    justify-content space-between
  }
  &__inner {
    display flex
    align-items center
  }
  &__itm {
    padding 0 5px
  }
  &__name {
    color: blueColor;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__user {
    display block
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #d8d8d8;
    margin-right 10px
  }
  &__lang {
    color: grey;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
  }
  &__flag {
    display block
    width: 21px;
    height: 21px;
    border-radius: 50%;
    margin-right: 10px;
  }
  &__btn {
    display: block;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    border-left: 1px solid #979797;
    border-bottom: 1px solid #979797;
    transition: border-color .2s;
  }
  &__bottom {
    opacity 0
    visibility hidden
    transition opacity 0.2s, visibility 0.2s
    display flex
    margin-top: -15px;
    position: absolute;
    width: 300px;
    top: 100%;
    left: -10px;
    min-height: 100px;
    border-radius 10px
    padding 15px
    background #fff
    border: solid 1px rgba(94,101,108,0.32);
    box-shadow: 0 2px 9px rgba(0,62,104,0.5);
    &:before {
      content ''
      position absolute
      left 0
      right 0
      margin 0 auto
      top -14px
      display: block;
      transform: rotate(134deg);
      width: 0;
      height: 0;
      border: 15px solid;
      border-color: transparent transparent #fff #fff;
      box-shadow: -2px 2px 2px 0 rgba(0,62,104,0.2)
    }
    .popup__close {
      position absolute
      right 15px
      top 15px
    }
  }
  &__list {
    margin auto
    text-align center
  }
  &__list-leng {
    .dropdown__li {
      margin-bottom 30px
      &:last-child {
        margin-bottom 0
      }
    }
  }
  &__link {
    text-decoration none
    color: #0f73e7;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    transition opacity 0.2s
    &:hover {
      opacity 0.2s
    }
    &-leng {
      display flex
      align-items: center
    }
  }
  &-leng {
    min-width: 120px;
    margin-right: 20px;
    .dropdown__outer {
      height 100%
    }
    .dropdown__bottom {
      width 200px
      left: -35px
      z-index 5
      padding: 30px 15px
    }
  }
  &-user-edit {
    min-width: auto;
    position: relative;
    height: 100%;
    padding: 0;
  }
}

.dropdown-user-edit {
  .title-h2 {
    margin-bottom 20px
  }
  .btn-a, .btn-b {
    font-size: 14px;
    font-weight: 500;
  }
}

.dropdown-tag-edit-popup {
  width 545px
  min-height 200px
  top -100%
  left 90px
  flex-flow column
  z-index 2
  &:before {
    left: -14px;
    right: auto;
    top: 50px;
    display: block;
    transform: rotate(45deg);
  }
}

.dropdown-profile-card-color-popup {
  width 545px
  min-height 200px
  top -100%
  left 90px
  flex-flow column
  z-index 2
  &:before {
    left: -14px;
    right: auto;
    top: 50px;
    display: block;
    transform: rotate(45deg);
  }
}

.dropdown-email-edit-popup {
  width 810px
  min-height 200px
  top -100%
  left 90px
  flex-flow column
  z-index 2
  &:before {
    left: -14px;
    right: auto;
    top: 50px;
    display: block;
    transform: rotate(45deg);
  }
}

.dropdown-phone-edit-popup {
  width 1415px
  min-height 200px
  top 70px
  left: -150px;
  flex-flow column
  z-index 2
  &:before {
    left: 163px;
    right: auto;
    top: -14px;
    display: block;
    transform: rotate(135deg);
  }
}

.dropdown-view-note {
  min-width: auto;
}

.dropdown-view-note-popup {
  width 575px
  padding 30px
  right -30px
  left auto
  top 70px
  z-index 2
  &:before {
    left auto
    right 85px
  }
}

.dropdown-previously-ordered {
  min-width auto
  padding 0
  .dropdown__bottom {
    width 825px
    z-index 2
    top 110px
    left -400px
    &:before {
      left 135px
    }
  }
  .making-order-info {
    padding 20px 0
    width 100%
    &__col {
      justify-content space-between
    }
    &__price {
      line-height 27px
    }
  }
}

.dropdown-notify {
  min-width auto
  padding 0
  .dropdown__bottom {
    width 825px
    z-index 2
    top 110px
    left -540px
    &:before {
      left 450px
    }
  }
  .making-order-info {
    padding 20px 60px 20px 0
    width 100%
    position relative
    &__col {
      justify-content space-between
    }
    &__price {
      line-height 27px
      padding-top 8px
    }
  }
  .b-close {
    top 30px
  }
}

.dropdown-marking-saved {
  min-width auto
  padding 0
  .dropdown__bottom {
    width 825px
    z-index 2
    top 110px
    right 0
    left auto
    &:before {
      left auto
      right 30px
    }
  }
  .making-order-info {
    padding 20px 0
    width 100%
    &__col {
      justify-content space-between
    }
    &__price {
      line-height 27px
    }
  }
}