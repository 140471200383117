.calls-filters {

}

.calls-filters-title, .tabs-search-title {
	display flex
	justify-content space-between
	margin-bottom 25px
	&__col {
		display flex
		align-items flex-end
	}
	.title-h3 {
		font-size 20px
		font-weight 500
	}
	.btn-calls-filters {
		font-size 20px
		font-weight 300
		margin-left 25px
		text-decoration underline
		color #0F73E7
		cursor pointer
	}
}

.call-type-list {
	display flex
	align-items: stretch;
	flex-wrap: nowrap;
}

.call-type-tags {
	display flex
	align-items center
	border-radius 6px
	background #e3f2fd
	padding 6px 12px
	margin-right 15px
	&__title {
		font-size 14px
		line-height 20px
		font-weight 300
		margin-right 4px
		color #0544A4
	}
	&__status {
		font-size 14px
		line-height 20px
		font-weight 500
		color #0544A4
		margin-right 7px
	}
	&__clear {
		display block
		width: 17px;
		position relative
		height: 17px;
		cursor pointer
		&:after,
		&:before {
			position: absolute;
			content: '';
			display: block;
			background: #0544A4;
			width: 2px;
			height: 100%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: background .2s;
		}
		&:after {
			transform: rotate(45deg);
		}
		&:before {
			transform: rotate(-45deg);
		}
	}
}

.calls-filters-input {
	display flex
	align-items: stretch;
	.form__label {
		margin-bottom 20px
		margin-right 20px
	}
	& + .calls-filters-input {
		margin-top 15px
	}
}

.calls-filters-check {
	display flex
	align-items stretch
	&__box {
		display flex
		align-items center
		margin-bottom 25px
	}
	.checkbox {
		margin-right 20px
	}
}

.calls-icon-table {
	display flex
	align-items center
	.icon {
		margin-right 12px
	}
}

.calls-filters-extended {
	display none
	&.show {
		display block
	}
}
