.tooltip {
  min-width 280px
  height 100%
  opacity 0
  visibility hidden
  transition opacity 0.2s, visibility 0.2s
  display flex
  position: absolute;
  width: 250px;
  top: 100%;
  left: 50%;
  transform translateX(-50%)
  min-height: 100px;
  border-radius 10px
  padding 15px
  background #fff
  border: solid 1px rgba(94,101,108,0.32);
  box-shadow: 0 2px 9px rgba(0,62,104,0.5);
  margin-top 20px ;
  &:before {
    content ''
    position absolute
    left 0
    right 0
    margin 0 auto
    top -14px
    display: block;
    transform: rotate(134deg);
    width: 0;
    height: 0;
    border: 15px solid;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px 0 rgba(0,62,104,0.2)
  }
  &__list {
    margin auto
    text-align center
  }
  &__link {
    text-decoration none
    color: #0f73e7;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    transition opacity 0.2s
    &:hover {
      opacity 0.2s
    }
  }
}
.show-tooltip {
  &:hover {
    .tooltip {
      opacity: 1;
      visibility visible
    }
  }
}