.btn-a {
  display block
  text-align center
  padding 15px
  background blueColor
  color #fff
  transition opacity 0.2s
  font-size: 16px;
  font-weight: 700;
  text-transform uppercase
  border-radius 3px
  margin 0 auto
  min-width 210px
  &:hover {
   opacity 0.7
  }
  &.btn-a--small {
    min-width 150px
    padding 15px 30px
  }
  &.btn-a--red {
    background redColor
  }
}
.btn-b {
  display inline-block
  text-align center
  transition opacity 0.2s
  font-size: 16px;
  font-weight: 700;
  color blueColor
  text-transform uppercase
  &:hover {
    opacity 0.7
  }
}
.btn-c {
  text-transform uppercase
  border-radius: 10px;
  display flex
  align-items center
  justify-content center
  text-decoration none
  font-size: 16px;
  font-weight: 700;
  padding 0 15px
  transition opacity 0.2s
  &:hover {
    opacity 0.7
  }
  &--blue {
    color: blueColor;
    background blueBrand
  }
  &--green {
    color: #fff;
    background greenBrand
  }
  &--orange {
    color: #fff;
    background orangeBrand
  }
  &--red {
    color: #fff;
    background redColor
  }
  .icon {
    margin-right 10px
  }
  &--col {
    flex-flow: column
    font-size 12px
    text-align: center
    .icon {
      margin-right 0
      margin-bottom: 8px
    }
  }
  &--call {
    width 235px
  }
}
.btn-d {
  display block
  border 2px solid #006fea
  align-items center
  text-align center
  text-decoration none
  font-size: 16px;
  font-weight: 700;
  padding 10px 15px
  color: #006fea;
  text-transform: uppercase;
  transition color 0.2s, background 0.2s
  &--active {
    color #fff
    background #006fea
  }
  &:hover {
    color #fff
    background #006fea
  }
}
.btn-e {
  border-radius 4px
  border 2px solid rgba(0,0,0, .12)
  font-size 14px
  font-weight 500
  color #165FFF
  padding 7px 15px
  text-transform uppercase
  display block
  cursor pointer
}
.btn-dd {
  display flex
  align-items center
  flex 0 0 auto
  border-radius 4px
  text-transform uppercase
  font-size 14px
  font-weight 500
  padding 10px 15px
  &--o {
    color blueColor
    background-color transparent
  }
}

.btn-del {
  cursor pointer
}

.btn-header-flex {
  display flex
  align-items stretch
  justify-content flex-end
  &__link {
    display flex
    text-align center
    align-items center
    border-radius 20px
    background #FFFFFF
    box-shadow 0 2px 2px 0 rgba(56,142,200,0.30)
    font-size 18px
    font-weight 500
    padding 20px 18px
    color #0F73E7
    margin-left 20px
  }
}

.btn-making-order {
  height 40px
  display flex
  align-items center
  justify-content center
  font-size 14px
  font-weight 400
  color #FFFFFF
  background-color #2979ff
  padding 0 15px
  border-radius 4px
  margin-left 20px
  text-transform uppercase
  cursor pointer
  &.check {
    background-color #e3f2ff
    color #0f73e7
  }
}

.btn-info {
  width 40px
  height 40px
  display flex
  align-items center
  justify-content center
  margin-left 20px
  background-color #E3F2FF
  border-radius 2px
  cursor pointer
}