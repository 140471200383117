.task {
  border-left 8px solid transparent
  padding 20px 30px 20px 20px
  &.task--overdue {
    background redLight
    border-color redColor
    .task__status {
      color redColor
    }
    .task__text {
      color redColor
    }
    .task__ttl {
      color redColor
    }
  }
  &--high {
    background redLight
    border-color redColor
    .task__status {
      color redColor
    }
  }
  &--medium {
    background yellowLight
    border-color yellowColor
    .task__status {
      color yellowColor
    }
  }
  &--low {
    background greenLight
    border-color greenBrand
    .task__status {
      color greenBrand
    }
  }
  &__text {
    margin-bottom 5px
    color: #3a3a3a;
  }
  &__ttl {
    font-weight: 700;
    margin-bottom 10px
    display inline-block
    color #333
    text-decoration none
    transition opacity 0.2s
    &:hover {
      opacity 0.7
    }
  }
  &__outer {
    display flex
    justify-content space-between
    margin 0 -20px
  }
  &__itm {
    padding 0 20px
  }
  &__inner {
    align-items flex-end
    height 100%
    display flex
    flex-direction column
    justify-content space-between
  }
  &__links {
    display flex
    align-items center
    margin 0 -10px
    .task__link {
      margin 0 10px
    }
  }
  &__link {
    color: #0f73e7;
    text-decoration underline
    &:hover {
      text-decoration none
    }
  }
}