.header {
  position fixed
  background: blueLight;
  left 0
  top 0
  width 100%
  z-index 3
  &__wrap {
    display flex
    justify-content space-between
    margin 0 -10px
    min-height 100px
  }
  &__wrap-itm {
    padding 0 10px
  }
  &__wrap-inner {
    background #fff
    padding 0 30px
    border-bottom-left-radius 15px
    height 100%
    border-bottom-right-radius 15px
  }
  &__wrap-left {
    flex-grow 1
  }
  &__outer {
    display flex
    align-items center
    margin 0 -10px
  }
  &__outer--between {
    justify-content space-between
    align-items stretch
    height 100%
  }
  &__nav {
    margin 0 -30px
    align-items stretch
  }
  &__itm {
    padding 0 10px
  }
  &__nav-itm {
    padding 0 30px
  }
  &__buttons {
    padding: 10px 0;
    align-items stretch
    height 100%
  }
  &__call {
    font-size: 24px;
    display: flex;
    align-items: center;
    height 100%
  }
  .btn-c {
    height 100%
  }
  .logo {
    display flex
    margin auto
  }
  .icon--call {
    margin-right 10px
  }
}
