.filter {
  &__outer {
    display flex
    align-items center
    margin 0 -20px
  }
  &__itm {
    padding 0 20px
  }
  &__itm-checkbox {
    padding-right 0
  }
  &__checkbox {
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 400;
  }
  &__buttons {
    margin-bottom 30px
    display flex
    .btn-d {
      min-width 185px
      border-right: none
      &:last-child {
        border-right 2px solid
      }
    }
  }
}

.filters-date {
  display flex
  margin-right 20px
  .date {
    margin-bottom 20px
  }
}