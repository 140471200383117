.login {
  &__modal {
    box-shadow: 0 2px 9px rgba(0,62,104,0.5);
    width: 430px;
    border: solid 1px rgba(94,101,108,0.32)
    background: #ffffff;
    border-radius 10px
    padding 30px 30px 5px
    &.login--password {
      width: 760px;
    }
    &.login--check {
      width: 500px;
    }
  }
  &__wrap {
    margin 0 auto
    width 370px
  }
  .logo {
    margin 0 auto 40px
  }
  .title-h2 {
    text-align center
    margin-bottom 30px
  }
}
