.toggle-box {
	display flex
	align-items center
	span {
		padding-left 7px
	}
}

.toggle-check {
	position relative
	width 34px
	height 20px
	cursor pointer
	&:after {
		content ''
		width 100%
		height 14px
		background-color rgba(0, 0, 0, .4)
		border-radius 7px
		position absolute
		left 0
		top: 50%;
		transform: translateY(-50%);
	}
	&:before {
		content ''
		width 20px
		height 20px
		border-radius 50%
		position absolute
		top 0
		left 0
		background-color #fff
		transform translateX(0)
		box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
		z-index 1
	}
	&.active-check {
		&:after {
			background-color rgba(41, 98, 255, .4)
		}
		&:before {
			background-color #2979FF
			transform: translateX(70%);
		}
	}
}

.toggle-check-info {
	&.toggle-active {
		opacity .4
	}
}