.search-members {
	.title-h2 {
		margin-left 0
		font-size 20px
		color #5E656C
		margin-bottom 25px
	}
	&__filter {
		display flex
		align-items stretch
		justify-content flex-start
		width 1010px
		.search {
			width 100%
			margin-bottom 0
			border-right-color transparent
		}
		.btn-dd {
			border-radius 0
		}
	}
}

.search-market {
	display flex
	align-items stretch
	flex 1
	width 0
	.search {
		margin-bottom 0
		flex 1
		width 0
	}
	.btn-dd {
		border-radius 0
	}
}