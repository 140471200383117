.table {
  td {
    padding 7px 30px 8px 0
  }
}
.table-members-list, .table-calls-list, .table-orders{
  width: 100%;
  margin-bottom: 25px;
  border-radius 20px
  background-color white
  table {
    width 100%
  }
  tr {
    border-bottom: 1px solid #CCCCCC;
    &:first-child {
      border-bottom: 2px solid #f0f4f9;
    }
    &:last-child {
      border-bottom none
    }
  }
  .header-td {
    padding: 30px 30px 30px 0;
    font-weight 500
    &:first-child {
      padding-left 37px
    }
    span {
      display flex
      align-items: center;
    }
  }
  .body-td {
    padding: 15px 30px 15px 0;
    vertical-align: middle;
    &:first-child {
      padding-left 37px
    }
    a {
      color #0F73E7
      text-decoration underline
    }
    .tags {
      margin 0
      display inline-flex
    }
    .file-link {
      display flex
      align-items center
      text-decoration none
      img {
        margin-right 5px
      }
    }
  }
  .btn-list-table {
    display flex
    align-items stretch
    justify-content flex-end
    .btn-a, .btn-c {
      font-size 14px
      font-weight 500
      color white
      text-decoration none
      flex-flow row
      align-items center
      margin 0 0 0 20px
      border-radius 3px
    }
    .icon {
      margin-bottom 0
    }
  }
  .form-list-header {
    display flex
    align-items center
    justify-content space-between
    padding 30px 30px 0px 35px
  }
}

.table-orders {
  table {
    width 1380px
  }
}

.col-3 {
  width 33%
}

.table-label-box {
  &__title {
    font-size 24px
    font-weight 500
    color #333
    margin-bottom 20px
  }
  table {
    width 100%
  }
  td {
    padding 0 20px 10px 0
    span {
      display block
      margin-bottom 10px
    }
  }
  td {
    &:first-child {

    }
    &:last-child {
      font-size 16px
      font-weight 500
    }
  }
}

.table-log-issue {
  width 100%
  margin-bottom 35px
  tr {
    border-bottom 1px solid #cecece
  }
  .header-td {
    font-size 14px
    font-weight: 600
    padding: 0 20px 10px 0;
  }
  .body-td {
    padding 13px 20px 13px 0
    position relative
    &__top {
      vertical-align: top;
      padding 30px 20px 13px 0
      &--toogle {
        padding 13px 20px 13px 0
      }
    }

  }
  .counter-box {
    margin-left 0
  }
}

.td-checkbox {
  width 100%
  display flex
  align-items center
  &__align-top {
    align-items flex-start
  }
  .checkbox {
    margin 0 10px
  }
}

.table-info-product {
  &__title {
    font-size 16px
    font-weight 300
    color #3A3A3A
    margin-bottom 3px
  }
  &__content {
    display flex
    align-items center
    &--text {
      font-size 16px
      font-weight 300
      color #3A3A3A
      margin-right 25px
      &:last-child {
        margin-right 0
      }
    }
  }
}

.box-check-text {
  display flex
  align-items center
  span {
    font-size 16px
    font-weight 300
    color #3A3A3A
  }
}

.attachment-file {
  width 100%
  display flex
  align-items center
  justify-content space-between
  &__title {
    font-size 16px
    font-weight 300
    color #3A3A3A
    margin-right 24px
  }
  &__box {
    width 100%

  }
  .inputfile {
    width 0.1px
    height 0.1px
    opacity: 0
    overflow hidden
    position absolute
    z-index: -1
  }
  .inputfile + label {
    width: 100%
    font-size 16px
    font-weight 300
    color #3A3A3A
    text-overflow: ellipsis
    white-space nowrap
    cursor pointer
    display inline-block
    overflow hidden
    /* 10px 20px */
    position relative
    border: 1px solid #cecece
    border-radius: 5px
    padding 18px 15px 15px
  }
}