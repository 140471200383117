.logo {
  display block
  width: 110px;
  height: 70px;
  &__img {
    max-width 100%
    display block
  }
  &.logo--small {
    width: 80px;
    height: 50px;
  }
}

